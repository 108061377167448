import React, { useEffect, useRef } from 'react';

const LazyLoadImage = ({ src, alt }) => {
  const imageRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const img = entry.target;
            img.src = src;
            img.alt = alt;
            observer.unobserve(img);
          }
        });
      },
      { threshold: 0.1 },
    );

    observer.observe(imageRef.current);

    return () => observer.disconnect();
  }, [src, alt]);

  return <img width={'100%'} ref={imageRef} />;
};

export default LazyLoadImage;
