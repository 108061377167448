import React, { useEffect, useRef, useState } from 'react';
import { AppBar, Box, Switch, Toolbar, Typography } from '@material-ui/core';
import { Button, Snackbar } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ContentLoader from 'react-content-loader';
import LazyLoadImage from './LazyLoadImage';
import { v4 as uuid } from 'uuid';

import './App.css';

const API_URL =
  'https://wi4qx4lvgb4gu5s5c2ee36mbay0jvteg.lambda-url.us-east-1.on.aws';

const UPLOAD_URL =
  'https://kboz5wj2qta3357tfonezgwa5i0aorkc.lambda-url.us-east-1.on.aws';

const App = () => {
  const [files, setFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [count, setCount] = useState(0);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    fetch(`${API_URL}/all`)
      .then((res) => res.json())
      .then((files) => {
        setFiles(files);
        setCount(files.length);
      });
  }, []);

  const remove = (file) => {
    fetch(`${API_URL}/remove?filename=${file}`)
      .then((res) => res.text())
      .then(() => fetch(`${API_URL}/all`))
      .then((res) => res.json())
      .then((files) => {
        setFiles(files);
        setCount(files.length);
      });
    setOpen(true);
    setMessage('Image successfully removed');
  };

  const uploadPhoto = async (e) => {
    const promises = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      const filename = encodeURIComponent(uuid());
      const fileType = encodeURIComponent(file.type);

      const res = await fetch(
        `${UPLOAD_URL}/?file=${filename}&fileType=${fileType}`,
      );
      console.log(`${UPLOAD_URL}/?file=${filename}&fileType=${fileType}`);
      const { url, fields } = await res.json();

      const formData = new FormData();

      Object.entries({ ...fields, file }).forEach(([key, value]) => {
        formData.append(key, value);
      });

      setOpen(true);
      setMessage('Uploading...');

      const uploadPromise = fetch(url, {
        method: 'POST',
        body: formData,
      }).then((upload) => {
        if (upload.ok) {
          console.log('Uploaded successfully!');
          setOpen(true);
          setMessage('Uploaded successfully');
        } else {
          console.error('Upload failed.');
          setOpen(true);
          setMessage('Upload failed');
        }
      });
      promises.push(uploadPromise);
    }
    await Promise.all(promises);
    fetch(`${API_URL}/all`)
      .then((res) => res.json())
      .then((files) => {
        setFiles(files);
        setCount(files.length);
      });
  };

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <AppBar position='sticky'>
        <Toolbar>
          <Button variant='contained' color='secondary' component='label'>
            Upload
            <input
              type='file'
              id='file'
              onChange={uploadPhoto}
              style={{ display: 'none' }}
              accept='image/png, image/jpeg, image/jpg, image/gif'
              multiple
            />
          </Button>
          <Box style={{ flexGrow: 1 }} />
          <Switch checked={checked} onChange={handleChange} />
        </Toolbar>
      </AppBar>
      <AppBar position='static' style={{ backgroundColor: 'white' }}>
        <Toolbar>
          <Typography
            variant='h6'
            style={{ marginRight: 'auto', color: 'black' }}
          >
            {count} images
          </Typography>
        </Toolbar>
      </AppBar>
      <div class={checked ? 'container-2' : 'container'}>
        {files.map((file) => (
          <div key={file} class='item'>
            <LazyLoadImage
              src={`https://pablosbot-cdn.s3.us-east-1.amazonaws.com/${file}`}
              alt={file}
            />
            <Button onClick={() => remove(file)}>
              <DeleteIcon />
            </Button>
          </div>
        ))}
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id='message-id'>{message}</span>}
        action={[
          <IconButton
            key='close'
            aria-label='close'
            color='inherit'
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </div>
  );
};

export default App;
